import { TetrisServiceException as __BaseException } from "./TetrisServiceException";
export class ApiError extends __BaseException {
    name = "ApiError";
    $fault = "client";
    errorMessage;
    constructor(opts) {
        super({
            name: "ApiError",
            $fault: "client",
            ...opts
        });
        Object.setPrototypeOf(this, ApiError.prototype);
        this.errorMessage = opts.errorMessage;
    }
}
export const Runtime = {
    JVM: "Java Virtual Machine",
    NATIVE: "Kotlin Native",
};
